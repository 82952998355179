import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";

export default function DashboardSofa() {
  const navigate = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.pouffe.az/pouffe/sofa.php/", {
        withCredentials: false,
      })
      .then((response) => {
        setProducts(response.data);
        setIsLoading(false);
      });
  }

  const deleteUser = (id) => {
    // SweetAlert confirmation
    Swal.fire({
      title: "Bu məhsulu silmək istədiyinizə əminsiniz?",
      text: "Bu əməliyyat geri qaytarıla bilməyəcək!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Bəli, sil!",
      cancelButtonText: 'Xeyr, ləğv et'
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with delete if confirmed
        axios
          .delete("https://www.pouffe.az/pouffe/sofa.php", {
            data: { id },
            withCredentials: false,
          })
          .then(() => {
            Swal.fire("Silindi!", "Məhsul silindi", "success");
            getProducts();
          })
          .catch((error) => {
            Swal.fire("Error", "There was an issue deleting the item.", "error");
            console.error("Error deleting user:", error);
          });
      }
    });
  };

  const updateUserLink = (id) => `/dashboard/sofa/update/${id}`;

  return (
    <>
      <DashboardHeader />
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <p className="text-xl font-semibold text-gray-500">Loading...</p>
        </div>
      ) : (
        <div className="container mx-auto p-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b-2 border-gray-200 text-left leading-4 text-gray-600 tracking-wider">Actions</th>
                  <th className="px-6 py-3 border-b-2 border-gray-200 text-left leading-4 text-gray-600 tracking-wider">ID</th>
                  <th className="px-6 py-3 border-b-2 border-gray-200 text-left leading-4 text-gray-600 tracking-wider">Rəng</th>
                  <th className="px-6 py-3 border-b-2 border-gray-200 text-left leading-4 text-gray-600 tracking-wider">Rəng kodu</th>
                  <th className="px-6 py-3 border-b-2 border-gray-200 text-left leading-4 text-gray-600 tracking-wider">İkinci rəng kodu</th>
                  <th className="px-6 py-3 border-b-2 border-gray-200 text-left leading-4 text-gray-600 tracking-wider">Şəkil</th>
                </tr>
              </thead>
              <tbody>
                {products?.map((user, key) => (
                  <tr key={key} className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 flex gap-2">
                      <button
                        onClick={() => deleteUser(user.id)}
                        className="text-white bg-red-500 hover:bg-red-600 px-3 py-1 rounded"
                      >
                        Sil
                      </button>
                      {/* <Link to={updateUserLink(user.id)}>
                        <button className="text-white bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded">Yenilə</button>
                      </Link> */}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{user.id}</td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{user.name}</td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{user.colorCode}</td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{user.secondColorCode}</td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      <img src={`https://pouffe.az/pouffe/${user.image}`} alt="Product" className="h-16 w-16 object-cover" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
