import React from 'react'

const About = () => {
  return (
    <>
      <div className='h-40 bg-[#E3D5F5] text-center flex flex-col justify-end'>
        <h1 className='font-semibold text-2xl'>Haqqımızda</h1>
        <p className='mb-5'>Müasir və rahat pufiklər ilə evinizə yeni bir rahatlıq qatırıq</p>
      </div>
      <div className='max-w-container w-[80%] mx-auto pt-7 pb-7 '>
      <p className='text-center'>Biz kimik?</p>
      <p className='text-3xl text-center'>POUFFE.AZ</p>
       <div className='block md:flex mt-6 mb-12 justify-between'>
       <div className='md:w-1/2'>
        <p>"Pouffe oturacaqları"  adətən yumşaq və rahat oturacaq çeşididir. Onlar müxtəlif ölçülərdə və formalarda ola bilər və içərisində xüsusi materiallar, ən çox hallarda poliuretan köpüyü və ya qranullar, ilə doldurulmuşdur. Bu oturacaqlar evlərin salonlarında, uşaq otaqlarında və hətta ofislərdə istifadəyə yararlıdır. Rənglər və dizaynlar geniş seçimlər təklif edir, bu da onları interyerin müxtəlif hissələrinə uyğunlaşdırmağa imkan verir. Puff oturacaqlarının  üstünlükləri:</p>
        <ul className='text-left pl-4 w-full mx-auto'>
          <li>— <span className='font-semibold'>Rahatlıq</span>: Yumşaq və esnek strukturları sayəsində bədənin formasına uyğunlaşır. Xüsusilə uzun müddət oturarkən bel və kürək dəstəyi təmin edir.</li>
          <li>— <span className='font-semibold'>Yüngüllük və Daşıma Asanlığı</span>: Çox yüngül olduqları üçün bir yerdən başqa yerə asanlıqla daşına bilər. Bu, otaqları tez-tez dəyişdirənlər üçün böyük üstünlükdür.</li>
          <li>—  <span className='font-semibold'>İstifadə Çeşidliliyi</span>: Oyun oynamaq, oxumaq, televiziya izləmək və ya sadəcə istirahət etmək üçün idealdır. Çox məqsədli istifadə imkanı təmin edir.</li>
          <li>—  <span className='font-semibold'>Müxtəlif Dizaynlar və Rənglər</span>:Geniş dizayn və rəng seçimləri ilə interyerə uyğunlaşa bilir, bu da dekorasiya baxımından çeviklik verir.</li>
          <li>— <span className='font-semibold'>Asan Təmizlik</span>: Çox vaxt çıxarıla bilən örtüklərlə təchiz olunurlar, bu da onların yuyulmasını və təmizlənməsini asanlaşdırır.</li>
          <li>— <span className='font-semibold'>Uşaqlar üçün Təhlükəsizdir</span>: Sərt küncləri və sərt hissələri olmadığı üçün uşaqlar üçün təhlükəsiz bir oturacaq seçimi ola bilər.</li>
          <li>— <span className='font-semibold'>Ekonomik</span>: Ənənəvi mebellərə nisbətən daha ucuz ola bilər və beləliklə, büdcə dostu bir seçimdir.</li>
          <li>— <span className='font-semibold'>Çoxməqsədli İstifadə</span>: Pufflar çox yönlüdür və evin müxtəlif hissələrində istifadə oluna bilər. Onlar yalnız oturmaq üçün deyil, həm də ayaqları uzatmaq üçün istifadə oluna bilər.</li>
          <li>— <span className='font-semibold'>Müxtəlif Məkanlarda Uyğunlaşma</span>: Pufflar az yer tutduğu üçün kiçik otaqlarda, balkonlarda və ya oyun otaqlarında istifadə üçün idealdır.</li>
          <li>— <span className='font-semibold'>Quraşdırma və Saxlama Asanlığı</span>: Pufflar adətən quraşdırmaya ehtiyac duyulmadan istifadəyə hazırdır. Onlar da asanlıqla saxlanıla bilər, bəziləri boşaldıla və yerə uyğun şəkildə yığıla bilər.</li>
        </ul>

       </div>
       <div className='mt-5 md:w-2/5'>
        <img src='./about-img.png'></img>
       </div>
       </div>
      </div>
    </>
  )
}

export default About
