import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios"; // axios'u içe aktar
import { resetCart } from "../../Components/redux/orebiSlice";
import { emptyCart } from "../../assets/images/index";
import ItemCard from "./ItemCard";
import Modal from "./Modal";
import { toast } from "react-toastify";

const Basket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const products = useSelector((state) => state.orebiReducer.products);
  const [totalAmt, setTotalAmt] = useState("");
  const [shippingCharge, setShippingCharge] = useState("");
  const [selectedItems, setSelectedItems] = useState(
    products.reduce((acc, product) => {
      const key = `${product.id}_${product.color}_${product.size}`;
      acc[key] = true; // All items selected by default 
      return acc;
    }, {})
  );

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");

  useEffect(() => {
    let price = 0;
    products.forEach((item) => {
      const key = `${item.id}_${item.color}_${item.size}`;
      if (selectedItems[key]) {
        price += item.price * item.quantity;
      }
    });
    setTotalAmt(price);
  }, [products, selectedItems]);

  useEffect(() => {
    if (totalAmt <= 1) {
      setShippingCharge(0);
    } 
    else if (totalAmt <= 100) {
      setShippingCharge(10);
    } else if (totalAmt < 300) {
      setShippingCharge(5);
    } else if (totalAmt >= 300) {
      setShippingCharge(0);
    }
  }, [totalAmt]);

  const handleSelectItem = (id, color, size ) => {
    const key = `${id}_${color}_${size}`;
    setSelectedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleSubmitOrder = async () => {
    // Seçilen ürünleri filtrele
    const selectedProducts = products.filter((item) => {
      const key = `${item.id}_${item.color}_${item.size}`;
      return selectedItems[key];
    });
    console.log(selectedProducts)

    let finalProductsFormat = '';
    selectedProducts.forEach(item => {
      finalProductsFormat +=  item.quantity + " eded "  + "rengi " + item.color + " ve " + "olcusu " + item.size + " olan " + item.name + ", " ;
    });

    let inputs = {name:  clientName, email: clientEmail, phone: clientPhone, address: clientAddress, products: finalProductsFormat, totalAmt: totalAmt, shippingCharge: shippingCharge}
    try {
      // Seçilen ürünleri post isteği ile gönder
      axios.post("https://www.pouffe.az/pouffe/orders.php/", inputs)
      .then(function (response) { 
        navigate("/"); 
        setclientName('')
        setclientEmail('')
        setclientPhone('')
        setclientAddress('')

        // console.log(inputs);
      });
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Sifarişiniz qeydə alındı");
      setIsModalOpen(false);
      dispatch(resetCart()); // Sepeti sıfırla
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
    }
  };

  return (
    <>
      <div className="max-w-container mx-auto px-4">
        <p className="mt-5 mb-5 text-[#969696]"><a href="/">Ana səhifə</a> &gt; <span className="text-[black] font-medium">Səbət</span></p>
        {products.length > 0 ? (
          <div>
            <p className="text-2xl">{products.length} məhsul</p>
            <div className="block md:flex justify-between">
              <div className="md:w-2/3 mr-10">
                <div className="mt-5">
                  {products.map((item) => (
                    <div key={`${item.id}_${item.color}_${item.size}`}>
                      <ItemCard
                        item={item}
                        isSelected={selectedItems[`${item.id}_${item.color}_${item.size}`]}
                        onSelect={() => handleSelectItem(item.id, item.color, item.size)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="md:w-1/3">
                <div className="bg-[#FAF9F9] rounded-lg border border-gray-200 md:p-6">
                  <div className="max-w-7xl flex justify-center md:justify-end mt-4">
                    <div className="w-full md:w-96 flex flex-col gap-4">
                      <h1 className="text-xl font-semibold">Sifariş</h1>
                      <div>
                        <p className="flex items-center justify-between text-lg px-4 font-medium text-gray-400">
                          Məbləğ
                          <span className="font-semibold tracking-wide font-titleFont text-black">
                            {totalAmt}₼
                          </span>
                        </p>
                        <p className="flex items-center justify-between text-lg px-4 font-medium text-gray-400">
                          Çatdırılma
                          <span className="font-semibold tracking-wide font-titleFont text-black">
                            {shippingCharge}₼
                          </span>
                        </p>
                        <div className="border-t border-gray-200 my-2"></div>
                        <p className="flex items-center justify-between text-lg px-4 font-medium">
                          Cəmi
                          <span className="font-bold tracking-wide text-lg font-titleFont text-black">
                            {totalAmt + shippingCharge}₼
                          </span>
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <button 
                          onClick={() => setIsModalOpen(true)} 
                          className="w-full h-12 bg-[#5A639C] text-white hover:bg-black duration-300 rounded-lg">
                          Səbəti təsdiqlə
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ( 
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
          >
            <div>
              <img
                className="w-80 rounded-lg p-4 mx-auto"
                src={emptyCart}
                alt="emptyCart"
              />
            </div>
            <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
              <h1 className="font-titleFont text-xl font-bold uppercase">
                Səbətiniz boşdur 
              </h1>
              <p className="text-sm text-center px-10 -mt-2">
              Rahatlığı məkanınızın bir küncündə hiss edin
              </p>
              <Link to="/modeller">
                <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                  Məhsullara keçid et
                </button>
              </Link>
            </div>
          </motion.div>
        )}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="p-4 bg-gray-50 rounded-t-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium text-gray-800">Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-800">
              &#10005;
            </button>
          </div>
          <form onSubmit={handleSubmitOrder}>
            <div className="mb-4">
              <label className="block text-gray-700">Ad, soyad*</label>
              <input type="text" className="border rounded-lg p-3 w-full bg-gray-100" 
               placeholder="Adınızı daxil edin"
               onChange={(event) => setclientName(event.target.value)}
               value={clientName}
               required
                />
               
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">E-mail*</label>
              <input type="email" className="border rounded-lg p-3 w-full bg-gray-100" 
              placeholder="Emailinizi daxil edin"
              onChange={(event) => setclientEmail(event.target.value)}
              value={clientEmail}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Əlaqə nömrəsi*</label>
              <input type="text" className="border rounded-lg p-3 w-full bg-gray-100" 
              placeholder="Telefon nömrənizi daxil edin"
              onChange={(event) => setclientPhone(event.target.value)}
              value={clientPhone}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Ünvan*</label>
              <input type="text" className="border rounded-lg p-3 w-full bg-gray-100" 
              placeholder="Ünvanınızı daxil edin"
              onChange={(event) => setclientAddress(event.target.value)}
              value={clientAddress}
              required /> 
            </div>
            <div className="flex justify-center">
              <button  type="submit" className="px-6 py-3 bg-[#5A639C] text-white rounded-lg w-full max-w-xs">Sifarişi tamamla</button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Basket;

