import React, { useEffect, useState } from "react";
import Others from "./Others";
import { useDispatch } from "react-redux";
import { addToCart, resetCart } from '../../Components/redux/orebiSlice';
import Modal from "./Modal"; // Import the Modal component
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Top() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [sofa, setSofa] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [selectedColor, setSelectedColor] = useState(""); 
  const [selectedQuantity, setSelectedQuantity] = useState(1); 
  const [selectedSize, setSelectedSize] = useState("L"); 
  const [selectedId, setSelectedId] = useState(3); 
  const [selectedDimension, setSelectedDimensions] = useState("60x90"); 
  const [selectedPrice, setSelectedPrice] = useState(95); 
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filter, setFilter] = useState("single");
  const [hasBothColorCodes, setHasBothColorCodes] = useState(false);
  const [hasCombo, setHasCombo] = useState(false); // Yeni durum ekledik
  const [hasStandart, setHasStandart] = useState(false); // Yeni durum ekledik

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");


  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.pouffe.az/pouffe/sofa.php/", {
        withCredentials: false
    }) 
      .then(function (response) { 
        setSofa(response.data);
        setSelectedProduct(response.data[0])
        setSelectedColor(response.data[0].name)
        const hasStandard = response.data.some(item => item.colorCode && !item.secondColorCode);
        const hasCombo = response.data.some(item => item.colorCode && item.secondColorCode);
        setHasStandart(hasStandard)
        setHasCombo(hasStandard && hasCombo);
        console.log(response.data)
        setIsLoading(false)
      });
  }

  useEffect(() => {
    console.log(hasCombo, hasStandart)
    if (!hasCombo && !hasStandart) {
      setFilter("combo");
    }
    else {
      setFilter("single");
    }
  }, [hasCombo, hasStandart]); // Değerler değiştiğinde useEffect yeniden çalışır.

  useEffect(() => {
    if (selectedSize === "L") {
      setSelectedPrice(95);
    } else if (selectedSize === "XL") {
      setSelectedPrice(125);
    } else if (selectedSize === "XXL") {
      setSelectedPrice(155);
    }
    else if (selectedSize === "XXXL") {
      setSelectedPrice(185);
    }
  }, [selectedSize]);

  useEffect(() => {
    if (sofa.length > 0) {
      setSelectedProduct(sofa[0]);
      setSelectedColor(sofa[0].name);
    }
  }, []);


  const handleColorChange = (color) => {
    setSelectedColor(color);
    const product = sofa.find(item => item.name === color);
    setSelectedProduct(product);
    console.log(selectedColor)
  };

    const changeSizeToL = () => {
    setSelectedSize("L");
    setSelectedDimensions("60x90")
    setSelectedPrice(95);
  }
  const changeSizeXL = () => {
    setSelectedSize("XL");
    setSelectedDimensions("80X110")
    setSelectedPrice(125);
  }
  const changeSizeXXL = () => {
    setSelectedSize("XXL");
    setSelectedDimensions("95X135")
    setSelectedPrice(155);

  }
  const changeSizeXXXL = () => {
    setSelectedSize("XXXL");
    setSelectedDimensions("110X150")
    setSelectedPrice(185);

  }



  const decreaseQuantity = () => {
    if(selectedQuantity > 1) {
      let quantity2 = selectedQuantity - 1;
      setSelectedQuantity(quantity2);
    }
  };

  const increaseQuantity = () => {
    let quantity2 = selectedQuantity + 1;
    setSelectedQuantity(quantity2);
  };

  const filteredColors = filter === "single" 
    ? sofa.filter(item => !item.name.includes("-")) 
    : sofa.filter(item => item.name.includes("-"));
 
    const handleSubmitOrder = async () => {
  
      let finalProductsFormat = '';
        finalProductsFormat +=  selectedQuantity  + " eded "  + "rengi " + selectedColor + " ve " + "olcusu " + selectedSize + " olan " + 'Sofa pouffe' + ", " ;

      let inputs = {name:  clientName, email: clientEmail, phone: clientPhone, address: clientAddress, products: finalProductsFormat, totalAmt: selectedQuantity * selectedPrice, shippingCharge: 3}
      try {
        // Seçilen ürünleri post isteği ile gönder
        axios.post("https://www.pouffe.az/pouffe/orders.php/", inputs)
        .then(function (response) { 
          navigate("/");  
          setclientName('')
          setclientEmail('')
          setclientPhone('')
          setclientAddress('')
  
          // console.log(inputs);
        });
        // Başarılı olduğunda yapılacak işlemler
        toast.success("Sifarişiniz qeydə alındı");
        setIsModalOpen(false);
      } catch (error) {
        // Hata durumunda yapılacak işlemler
        console.error("Sipariş gönderilirken bir hata oluştu:", error);
      }
    };

  return (
   <>
    {
       isLoading ? <div className="flex justify-center items-center">
       <img src="./loading.gif"></img>
     </div> :
       <div className="max-w-container w-[80%] mx-auto">
      <p className="mt-5 mb-5 text-[#969696]"><a href="/">Ana səhifə</a> &gt; <span className="text-[black] font-medium">Sofa</span></p>
      <div className="block lg:flex justify-between">
      <div className="w-full sml:w-1/2 lg:w-1/5 mr-10">
  {selectedProduct && (
    <div className="bg-white border-[#F1F1F1] border-2 rounded-lg p-4 mb-4 relative">
      <div className="mb-4 relative">

        <img 
                      src={`https://pouffe.az/pouffe/${selectedProduct.image}`} 
                      alt={selectedProduct.name} className="w-5/6 h-auto"
                    />
        <h4 className="text-lg font-semibold absolute top-0 right-0 bg-white bg-opacity-75 p-1 rounded">
          {selectedProduct.name}
        </h4>
      </div>
    </div>
  )} 
</div>
        <div className="w-4/5">
          <h4 className="text-[25px] font-medium mb-4">Sofa</h4>
          <h4 className="text-xl font-semibold mb-4 text-[#5A639C]">{selectedPrice} AZN</h4>
          <p className="mb-4 text-[14px] text-[#969696]">Sofa - modeli rahatlığa dəyər verənlər üçün ideal həlldir. 
Kreslo davamlılığı və xoş toxunma hisslərini təmin edən yüksək keyfiyyətli materiallardan hazırlanmışdır. <br></br>
<span className="text-[black]"> Material:  </span> suya davamlı və yüksək keyfiyyətli tekstil  <br></br>
<span className="text-[black]"> Doldurma:  </span> İdeal dəstək və bədən formasına uyğunlaşma təmin edən polistirol köpük qranulları  <br></br>
<span className="text-[black]"> Xüsusiyyətlər:  </span>
Asan təmizlənir, aşınmaya davamlıdır, istənilən interyerə uyğundur  <br></br>
<span className="text-[black]"> Üstünlüklər:  </span> Divanın erqonomik forması maksimum rahatlıq və istirahət təmin edir.   <br></br>
<span className="text-[black]"> Hərəkətlilik:  </span> Yüngül çəki stulun evin ətrafında hərəkətini asanlaşdırır. Rahat sapı sayəsində daşımaq asandır, hətta uşaq da onu idarə edə bilər.  <br></br>
<span className="text-[black]"> Çox yönlü: </span> Qonaq otağında, yataq otağında, uşaq otağında və ya ofisdə istifadə üçün uyğundur.   <br></br>
Sofa modeli ilə rahat istirahət guşəsi yaradın və hər andan həzz alın!   <br></br>
</p>
<h4 className="text-[16px] font-medium mb-4">Rənglər</h4>
{ hasCombo ?
            <>
            <div className="flex items-center mb-4">
                <label className="mr-4">
                  <input 
                    type="radio" 
                    name="color-filter" 
                    value="single" 
                    checked={filter === "single"} 
                    onChange={() => setFilter("single")}
                    className="mr-1"
                  />
                  Standart
                </label>
                <label>
                  <input 
                    type="radio" 
                    name="color-filter" 
                    value="combo" 
                    checked={filter === "combo"} 
                    onChange={() => setFilter("combo")}
                    className="mr-1"
                  />
                  Kombo
                </label>
              </div>
              <div className="flex flex-wrap gap-2 mb-4">
      {filteredColors.map((item, index) => (
        <button 
          key={index} 
          onClick={() => handleColorChange(item.name)} 
          className={`relative w-6 h-6 rounded-full border-2 ${selectedColor === item.name ? 'border-black' : 'border-transparent'}`}
          style={{
            backgroundColor: 'transparent', // Butonun arka planını şeffaf yapıyoruz
          }}
          title={item.name}
        >
          {item.secondColorCode ? (
            <div 
              className="absolute inset-0 rounded-full"
              style={{
                background: `linear-gradient(45deg, ${item.colorCode} 50%, ${item.secondColorCode} 50%)`,
                borderRadius: '50%',
              }}
            />
          ) : (
            <div 
              className="absolute inset-0 rounded-full"
              style={{
                backgroundColor: item.colorCode,
                borderRadius: '50%',
              }}
            />
          )}
        </button>
      ))}
    </div>
    </>  :
    <div className="flex flex-wrap gap-2 mb-4">
    {filteredColors.map((item, index) => (
      <button 
        key={index} 
        onClick={() => handleColorChange(item.name)} 
        className={`relative w-6 h-6 rounded-full border-2 ${selectedColor === item.name ? 'border-black' : 'border-transparent'}`}
        style={{
          backgroundColor: 'transparent', // Butonun arka planını şeffaf yapıyoruz
        }}
        title={item.name}
      >
        {item.secondColorCode ? (
          <div 
            className="absolute inset-0 rounded-full"
            style={{
              background: `linear-gradient(45deg, ${item.colorCode} 50%, ${item.secondColorCode} 50%)`,
              borderRadius: '50%',
            }}
          />
        ) : (
          <div 
            className="absolute inset-0 rounded-full"
            style={{
              backgroundColor: item.colorCode,
              borderRadius: '50%',
            }}
          />
        )}
      </button>
    ))}
  </div>
          }
          

<div className="flex items-center mb-4">
<a href="/sofa-uzluk"><button className="mt-4 px-4 py-2 bg-[#5A639C] text-white rounded-full w-[140px] md:w-[140px]">Üzlük al</button></a>
      </div>
          <h4 className="text-[16px] font-medium mb-4">Ölçülər</h4>

          <div>
      <button 
        onClick={changeSizeToL}
        className={`${
          selectedSize === "L" ? "bg-[#5A639C]" : "bg-[#F1F1F1] text-[black]" 
        } text-white rounded-md px-4 py-2 mr-2`}
      >
        L
      </button>
      <button
        onClick={changeSizeXL}
        className={`${
          selectedSize === "XL" ? "bg-[#5A639C]" : "bg-[#F1F1F1] text-[black]" 
        } text-white rounded-md px-4 py-2 mr-2`}
      >
        XL
      </button>
      <button
        onClick={changeSizeXXL}
        className={`${
          selectedSize === "XXL" ? "bg-[#5A639C]" : "bg-[#F1F1F1] text-[black]" 
        } text-white rounded-md px-4 py-2 mr-2`}
      >
        XXL
      </button>
      <button
        onClick={changeSizeXXXL}
        className={`${
          selectedSize === "XXXL" ? "bg-[#5A639C]" : "bg-[#F1F1F1] text-[black]" 
        } text-white rounded-md px-4 py-2 mr-2`}
      >
        XXL
      </button>
    </div>
          <h4 className="text-[16px] font-medium mb-4 mt-4">Ölçü dəyərləri: <span className="text-[#969696]">{selectedDimension}</span></h4>
          <h4 className="text-[16px] font-medium mb-4 mt-4">Say</h4>
          <div className="flex">
          <button className="h-[36px] w-[36px] border-[#F1F1F1] border-2" onClick={decreaseQuantity}>-</button>
          <p className="p-2">{selectedQuantity}</p>
            <button className="h-[36px] w-[36px] border-[#F1F1F1] border-2" onClick={increaseQuantity}>+</button>
          </div>
          <div className="md:flex">
            <button 
              onClick={() => setIsModalOpen(true)} // Open modal on button click
              className="mt-4 px-4 bg-[#5A639C] text-white py-2 border-[#5A639C] rounded-full border-2 w-[200px] md:w-[340px]"
            >
              Məhsulu al
            </button>
            <button 
              onClick={() =>
                dispatch(
                  addToCart({
                    id: selectedId,
                    name: 'Sofa',
                    quantity: selectedQuantity,
                    image: selectedProduct.image,
                    color: selectedColor,
                    price: selectedPrice,
                    size: selectedSize,
                  })
                )
              } 
              className="mt-4 px-4 py-2 border-[#5A639C] rounded-full border-2 w-[200px] md:w-[340px] md:ml-4"
            >
              Səbətə at
            </button>
          </div>
        </div>
      </div>
    </div>
    }

    {/* Modal */}
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="p-4 bg-gray-50 rounded-t-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium text-gray-800">Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-800">
              &#10005;
            </button>
          </div>
          <form onSubmit={handleSubmitOrder}>
            <div className="mb-4">
              <label className="block text-gray-700">Ad, soyad*</label>
              <input type="text" className="border rounded-lg p-3 w-full bg-gray-100" 
               placeholder="Adınızı daxil edin"
               onChange={(event) => setclientName(event.target.value)}
               value={clientName}
               required
                />
               
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">E-mail*</label>
              <input type="email" className="border rounded-lg p-3 w-full bg-gray-100" 
              placeholder="Emailinizi daxil edin"
              onChange={(event) => setclientEmail(event.target.value)}
              value={clientEmail}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Əlaqə nömrəsi*</label>
              <input type="text" className="border rounded-lg p-3 w-full bg-gray-100" 
              placeholder="Telefon nömrənizi daxil edin"
              onChange={(event) => setclientPhone(event.target.value)}
              value={clientPhone}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Ünvan*</label>
              <input type="text" className="border rounded-lg p-3 w-full bg-gray-100" 
              placeholder="Ünvanınızı daxil edin"
              onChange={(event) => setclientAddress(event.target.value)}
              value={clientAddress}
              required /> 
            </div>
            <div className="flex justify-center">
              <button  type="submit" className="px-6 py-3 bg-[#5A639C] text-white rounded-lg w-full max-w-xs">Sifarişi tamamla</button>
            </div>
          </form>
        </div>
      </Modal>

    <Others /> 
    </>
  );
}