import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './Carousel.css';
import { t } from "i18next";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: './group2.png', text: "Rahatlığı məkanınızın bir küncündə hiss edin", path: 'sofa' },
    { img: './group4.png', text: "Rahatlığı məkanınızın bir küncündə hiss edin", path:'top' },
    { img: './group1.png', text: "Rahatlığı məkanınızın bir küncündə hiss edin", path:'komfort' },
    { img: './group3.png', text: "Rahatlığı məkanınızın bir küncündə hiss edin", path: 'klassik' },
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      onSlideChange={(swiper) => {
        setShowText(false);
        setShowButton(false);
        setActiveIndex(swiper.activeIndex);
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="relative">
            {/* Mor arka plan */}
            <div className="w-full h-[60vh] sm:h-[80vh] lg:h-[90vh] bg-[#E3D5F5] flex items-center justify-center">
              {activeIndex === index && (
                <div className="max-w-container absolute inset-0 block md:flex justify-center items-center text-white p-4 lg:p-8 transition-opacity duration-1000 w-[90%] mx-auto">
                  <div className="w-full md:w-[50%] mx-auto mb-6 lg:mb-14 text-center lg:text-left">
                    <h2 className={` text-[black] text-2xl sm:text-4xl lg:text-5xl mb-4 lg:mb-8 transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {slide.text}
                    </h2>
                    <h2 className={`text-[black] text-xl lg:text-2xl mb-4 lg:mb-8 transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      Ailəniz üçün unikal pouffelar bizdə
                    </h2>

                    <div className="flex flex-col lg:flex-row justify-center lg:justify-start mt-4 lg:mt-8 space-y-3 lg:space-y-0 lg:space-x-6">
                      <a href={`/${slide.path}`}>
                        <button className={`bg-[#5A639C] btn btn-primary px-4 py-2 sm:px-6 sm:py-3 lg:px-8 lg:py-3 rounded-full transition-all duration-1000 transform ${showButton ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                          İndi al
                        </button>
                      </a>
                    </div>
                  </div>
                  {/* Resim */}
                  <div className="w-full md:w-[50%] flex justify-center md:justify-end">
                    <img
                      className="object-contain w-[300px] h-auto sm:w-[400px] lg:w-[500px] xl:w-[600px]"
                      src={slide.img}
                      alt="Mebel"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;


