import React from "react";
import { useTranslation } from "react-i18next";

export const price = [
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: "./partners1.jpeg",
  },
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: "./partners2.png",
  },
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: "./partners3.png",
  },
];

export default function Partners() {
  const { t } = useTranslation();

  return (
   <>
         <div className='h-40 bg-[#E3D5F5] text-center flex flex-col justify-end mb-10'>
        <h1 className='font-semibold text-2xl'>Tərəfdaşlarımız</h1>
        <p className='mb-5'>Müasir və rahat pufiklər ilə evinizə yeni bir rahatlıq qatırıq</p>
      </div>
    <div className='container mx-auto w-[80%] mb-10'>
      <div className='corporation content flex justify-center'>
        {price.map((item, index) => (
          <div className='box shadow-lg m-4 p-4 rounded-lg text-center' key={index}>
            <img
              src={item.image}
              alt={`Partnership plan ${index + 1}`}
              className='h-48 w-full object-cover rounded-md'
            />
          </div>
        ))}
      </div>
    </div>
   </>
  );
}
