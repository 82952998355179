import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import Swal from "sweetalert2"; // SweetAlert kütüphanesini içe aktardık

export default function DashboardContact() {
  const navigate = useNavigate();

  const logoutSubmit = () => {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  };

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    axios.get("https://www.pouffe.az/pouffe/contact.php/")
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Bu əlaqəni silmək istədiyinizə əminsiniz?",
      text: "Bu əməliyyat geri qaytarıla bilməyəcək!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: "Bəli, sil!",
      cancelButtonText: 'Xeyr, ləğv et'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`https://www.pouffe.az/pouffe/contact.php/${id}/delete`)
          .then(() => {
            getProducts();
            Swal.fire("Silindi!", "Əlaqə silindi", "success");
          })
          .catch((error) => {
            console.error("Error deleting user:", error);
            Swal.fire(
              'Hata!',
              'Mesaj silinirken bir hata oluştu.',
              'error'
            );
          });
      }
    });
  };

  return (
    <>
      <DashboardHeader />
      <div className="container mx-auto p-5">
        <h1 className="text-2xl font-bold mb-5">Contact Messages</h1>
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-3 px-4 text-left">Actions</th>
              <th className="py-3 px-4 text-left">ID</th>
              <th className="py-3 px-4 text-left">Ad</th>
              <th className="py-3 px-4 text-left">Telefon</th>
              <th className="py-3 px-4 text-left">Email</th>
              <th className="py-3 px-4 text-left">Mesaj</th>
            </tr>
          </thead>
          <tbody>
            {products.map((user) => (
              <tr key={user.id} className="border-b hover:bg-gray-100">
                <td className="py-3 px-4">
                  <button 
                    onClick={() => deleteUser(user.id)} 
                    className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600"
                  >
                    Sil
                  </button>
                </td>
                <td className="py-3 px-4">{user.id}</td>
                <td className="py-3 px-4">{user.name}</td>
                <td className="py-3 px-4">{user.phone}</td>
                <td className="py-3 px-4">{user.email}</td>
                <td className="py-3 px-4">{user.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
