import React from 'react'
import ProductCard from './ProductCard';
import { product1, product2, product3, product4 } from '../../assets/images';

const products = [
  {
    id: 1,
    title: 'Komfort',
    price: '165 Azn',
    image: product3, // Replace with the actual image path
    path:'/komfort'
  }, 
  {
    id: 2,
    title: 'Sofa',
    price: '95 Azn',
    image: product1, // Replace with the actual image path
    path:'/sofa'
  },
  {
    id: 4,
    title: 'Klassik',
    price: '190 Azn',
    image: product4, // Replace with the actual image path
    path:'/klassik'
  },
];

const Others = () => {
  return (
    <div className="max-w-container mx-auto py-8 mt-12 mb-7">
    <p className="text-center text-3xl font-medium mb-6">Digər məhsullar</p>
    <div>
    <div className="lg:flex gap-6 px-4 mx-auto w-3/4">
      {products.map((product) => (
        <a href={product.path}>
          <ProductCard
          key={product.id}
          title={product.title}
          price={product.price}
          image={product.image}
        />
        </a>
      ))}
    </div>
    </div>
  </div>
  )
}

export default Others