import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";

export default function Dashboard() {
  const navigate = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.pouffe.az/pouffe/orders.php/", {
        withCredentials: false,
      })
      .then((response) => setProducts(response.data));
  }

  const deleteUser = (id) => {
    Swal.fire({
      title: "Bu sifarişi silmək istədiyinizə əminsiniz?",
      text: "Bu əməliyyat geri qaytarıla bilməyəcək!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Bəli, sil!",
      cancelButtonText: 'Xeyr, ləğv et'
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://www.pouffe.az/pouffe/orders.php/${id}/delete`, {
            withCredentials: false,
          })
          .then(() => {
            Swal.fire("Silindi!", "Sifariş silindi", "success");
            getProducts();
          })
          .catch((error) => {
            Swal.fire("Error!", "There was an issue deleting the order.", "error");
          });
      }
    });
  };

  return (
    <>
      <DashboardHeader />
      <div className="container mx-auto p-6">

        <div className="overflow-x-auto shadow-lg rounded-lg">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">Actions</th>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">ID</th>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">Ad</th>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">Telefon</th>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">Email</th>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">Ünvan</th>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">Sifariş</th>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">Ümumi qiymət</th>
                <th className="py-3 px-6 bg-gray-100 font-bold text-gray-600 text-left uppercase text-sm">Çatdırılma qiyməti</th>
              </tr>
            </thead>
            <tbody>
              {products.map((user, index) => (
                <tr key={index} className="border-b">
                  <td className="py-4 px-6">
                    <button
                      onClick={() => deleteUser(user.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600"
                    >
                      Sil
                    </button>
                  </td>
                  <td className="py-4 px-6">{user.id}</td>
                  <td className="py-4 px-6">{user.name}</td>
                  <td className="py-4 px-6">{user.phone}</td>
                  <td className="py-4 px-6">{user.email}</td>
                  <td className="py-4 px-6">{user.address}</td>
                  <td className="py-4 px-6">{user.products}</td>
                  <td className="py-4 px-6">{user.totalAmt}</td>
                  <td className="py-4 px-6">{user.shippingCharge}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

