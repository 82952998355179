import React from 'react';
import { poster } from '../../../assets/images';

const Poster = () => {
  return (
    <div className="relative">
      <img src={poster} alt="Interior Design" className="w-full h-auto object-cover" />
      <div className="w-full md:w-1/2 mx-auto absolute inset-0 flex flex-col justify-center items-start p-4 md:p-8 text-white">
        <h1 className="text-xl md:text-4xl font-semibold">Rahatlığı məkanınızın bir küncündə hiss edin</h1>
        <p className=" md:mt-4 text-sm sm:text-base md:text-lg">Ailəniz üçün unikal pouffelar bizdə</p>
       <a href='/Pouffe-Kataloq.pdf' download>
       <button className="mt-4 sm:mt-5 md:mt-6 bg-[#5A639C] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full">Kataloqu yüklə</button>
            </a>
      </div>
    </div>
  );
};

export default Poster;

 
