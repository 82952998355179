import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import UpdateSofa from "../src/Pages/UpdateSofa/UpdateSofa";
import Protected from "../src/Pages/Protected/Protected";



import { ToastContainer } from "react-toastify";
import Komfort from "./Pages/Komfort/Komfort";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact"; 
import Basket from "./Pages/Basket/Basket";
import Klassik from "./Pages/Klassik/Klassik";
import Sofa from "./Pages/Sofa/Sofa";
import Top from "./Pages/Top/Top";
import Payment from "./Pages/Payment/Payment";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import Dashboard from "./Pages/Dashboard/Dashboard";
import CreateUser from "./Pages/CreateUser/CreateUser";
import DashboardContact from "./Pages/DashboardContact/DashboardContact";
import Modeller from "./Pages/Modeller/Modeller";
import Partners from "./Pages/Partners/Partners";
import KomfortUzluk from "./Pages/KomfortUzluk/KomfortUzluk";
import KlassikUzluk from "./Pages/KlassikUzluk/KlassikUzluk";
import SofaUzluk from "./Pages/SofaUzluk/SofaUzluk";
import TopUzluk from "./Pages/TopUzluk/Top";
import Uzlukler from "./Pages/Uzlukler/Uzlukler";
import DashboardSofa from "./Pages/DashboardSofa/DashboardSofa";
import DashboardSofaSizes from "./Pages/DashboardSofaSizes/DashboardSofaSizes";
import UpdateSofaSizes from "./Pages/UpdateSofaSizes/UpdateSofaSizes";
import CreateSofa from "./Pages/CreateSofa/CreateSofa";
import DashboardTop from "./Pages/DashboardTop/DashboardTop";
import CreateTop from "./Pages/CreateTop/CreateTop";
import DashboardKlassik from "./Pages/DashboardKlassik/DashboardKlassik"; 
import CreateKlassik from "./Pages/CreateKlassik/CreateKlassik";
import DashboardKomfort from "./Pages/DashboardKomfort/DashboardKomfort";
import CreateKomfort from "./Pages/CreateKomfort/CreateKomfort";



const Layout = () => {
  return (
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        theme="colored"
      /> 
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route path="/" element={ <Home />}></Route>
        <Route path="/about" element={ <About/>}></Route>
        <Route path="/partners" element={ <Partners/>}></Route>
        <Route path="/contact" element={ <Contact/>}></Route>
        <Route path="/modeller" element={ <Modeller/>}></Route>
        <Route path="/uzlukler" element={ <Uzlukler/>}></Route>
        <Route path="/basket" element={ <Basket/>}></Route>
        <Route path="/komfort" element={ <Komfort />}></Route>
        <Route path="/klassik" element={ <Klassik />}></Route>
        <Route path="/sofa" element={ <Sofa />}></Route>
        <Route path="/top" element={ <Top />}></Route>
        <Route path="/payment" element={ <Payment />}></Route>
        <Route path="/admin" element={ <AdminPanel />}></Route>
        <Route path="/dashboard" element={ <Dashboard />}></Route>
        <Route path="/dashboard/contact" element={ <DashboardContact />}></Route>

        <Route path="/dashboard/sofa" element={ <DashboardSofa />}></Route>
        <Route path="/dashboard/sofa/create" element={<Protected Component={CreateSofa} />} />
        {/* <Route path="/dashboard/sofa/update/:id" element={<Protected Component={UpdateSofa} />} />
        <Route path="/dashboard/sofa-size" element={ <DashboardSofaSizes />}></Route>
        <Route path="/dashboard/sofa-size/update/:id" element={<Protected Component={UpdateSofaSizes} />} /> */}
                <Route path="/dashboard/top" element={ <DashboardTop />}></Route>
                <Route path="/dashboard/top/create" element={<Protected Component={CreateTop} />} />

                <Route path="/dashboard/komfort" element={ <DashboardKomfort />}></Route>
                <Route path="/dashboard/komfort/create" element={<Protected Component={CreateKomfort

                } />} />

                <Route path="/dashboard/klassik" element={ <DashboardKlassik />}></Route>
                <Route path="/dashboard/klassik/create" element={<Protected Component={CreateKlassik} />} />

        <Route path="/dashboard/create" element={ <CreateUser />}></Route>
        <Route path="/komfort-uzluk" element={ <KomfortUzluk />}></Route>
        <Route path="/klassik-uzluk" element={ <KlassikUzluk />}></Route>
        <Route path="/sofa-uzluk" element={ <SofaUzluk />}></Route>
        <Route path="/top-uzluk" element={ <TopUzluk />}></Route>

      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;




