import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import { toast } from "react-toastify";

export default function CreateSofa() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  function handleChangeImage(event) {
    if (event.target.name === "image") {
      const file = event.target.files[0];
      if (file) {
        setInputs((values) => ({
          ...values,
          image: file
        }));
      }
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    
    formData.append('name', inputs.name);
    formData.append('colorCode', inputs.colorCode);
    formData.append('secondColorCode', inputs.secondColorCode);
    if (inputs.image) {
      formData.append('image', inputs.image);
    }

    try {
      await axios.post("https://www.pouffe.az/pouffe/sofa.php/save", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success("Sofa yaradıldı")
      navigate("/dashboard/sofa");
    } catch (error) {
      console.error("Error uploading the data", error);
    }
  };

  return (
    <>
      <DashboardHeader />
      <div className="max-w-2xl mx-auto p-8 bg-gray-100 rounded-lg shadow-lg mt-10 mb-10">
        <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">Sofa əlavə et</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Rəng</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Sofa rəngini daxil edin"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Rəng kodu</label>
            <input
              type="text"
              name="colorCode"
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Rəng kodunu daxil edin"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-2">İkinci rəng kodu</label>
            <input
              type="text"
              name="secondColorCode"
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="İkinci rəng kodunu daxil edin"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Şəkil</label>
            <input
              type="file"
              name="image"
              onChange={handleChangeImage}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="text-right">
            <button
              type="submit"
              className="px-6 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              Yadda saxla
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
